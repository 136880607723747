module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"/home/runner/work/website-2022/website-2022/node_modules/gatsby-remark-external-links","id":"8f246565-c87b-50d0-a7bc-71185c68f0d0","name":"gatsby-remark-external-links","version":"0.0.4","modulePath":"/home/runner/work/website-2022/website-2022/node_modules/gatsby-remark-external-links/index.js","pluginOptions":{"plugins":[],"target":"_blank","rel":"noindex nofollow"},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/website-2022/website-2022","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"de","configPath":"/home/runner/work/website-2022/website-2022/static/admin/i18n-config.json"},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/home/runner/work/website-2022/website-2022/src/cms/cms.js","enableIdentityWidget":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
